/*
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {getLocalStorageItem, LocalStorageKeys} from '../../utility/local-storage';
import {RootState} from '../configureStore';

export type SupportedRegions = 'NA' | 'EU' | 'AP' | 'Integ';

interface AppState {
    sideMenuOpen: boolean
    selectedRegion: SupportedRegions
}

const initialState: AppState = {
    sideMenuOpen: true,
    selectedRegion: getLocalStorageItem(LocalStorageKeys.ID4_REGION) as SupportedRegions ?? (window.location.hostname.includes('prod') ? 'NA' : 'Integ')
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setSideMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.sideMenuOpen = action.payload;
        },
        setSelectedRegion: (state, action: PayloadAction<SupportedRegions>) => {
            state.selectedRegion = action.payload;
        }
    }
});

export default appSlice.reducer;

export const {setSideMenuOpen, setSelectedRegion} = appSlice.actions;

export const selectSideMenuOpen = (state: RootState) => state.app.sideMenuOpen;

export const selectSelectedRegion = (state: RootState) => state.app.selectedRegion;

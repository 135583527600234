/*
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Box from '@amzn/meridian/box';
import Heading from '@amzn/meridian/heading';
import Masthead, {MastheadMenuButton, MastheadTitle} from '@amzn/meridian/masthead';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {useBundle} from '@amzn/react-arb-tools';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppContext} from '../../../app';
import {selectSideMenuOpen, setSideMenuOpen} from '../../../state/app/appSlice';
import LanguageMenu from './LanguageMenu';
import RegionMenu from './RegionMenu';
import UserSheet from './UserSheet';

const AppMasthead = () => {

    const [commonBundle] = useBundle('common.common');

    const USER_PROFILE_DIMENSION = '2em';

    const appContext = React.useContext(AppContext);

    const dispatch = useDispatch();

    const sideMenuOpen = useSelector(selectSideMenuOpen);

    return (
        <Masthead>
            <MastheadMenuButton onClick={() => dispatch(setSideMenuOpen(!sideMenuOpen))} open={true}/>
            <MastheadTitle href='/'>
                <Heading level={4}>ID4 Portal</Heading>
            </MastheadTitle>
            <RegionMenu/>
            <LanguageMenu/>
            <Row alignmentHorizontal='center' spacingInset='200' height='100%' spacing='300'>
                <Box type='outline' height={USER_PROFILE_DIMENSION} width={USER_PROFILE_DIMENSION}>
                    <div style={{
                        height: USER_PROFILE_DIMENSION,
                        width: USER_PROFILE_DIMENSION,
                        background: `url('https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${appContext?.username}') center center / cover no-repeat`
                    }}/>
                </Box>
                <Text type='b200'>{appContext?.username?.toUpperCase() ?? commonBundle?.getMessage('unknown') ?? ''}</Text>
            </Row>
            <UserSheet/>
        </Masthead>
    );
};

export default AppMasthead;

/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { SimCategorizationEntry, SimCategorizationType } from '@amzn/id4-mothership/com/amazon/id4notificationservice/model/types/sim';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import React from 'react';

interface SimCategorizationInputProps {
    categorizations: SimCategorizationEntry[];
    setCategorizations: (arg0: SimCategorizationEntry[]) => void;
}

export const SimCategorizationInput: React.FC<SimCategorizationInputProps> = ({ categorizations, setCategorizations }) => {
    const [buildingId, setBuildingId] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.BUILDING_ID)?.value);
    const [building, setBuilding] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.BUILDING)?.value);
    const [country, setCountry] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.COUNTRY)?.value);
    const [city, setCity] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.CITY)?.value);
    const [category, setCategory] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.CATEGORY)?.value);
    const [type, setType] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.TYPE)?.value);
    const [item, setItem] = React.useState<string>(categorizations?.find(cat => cat.key === SimCategorizationType.ITEM)?.value);

    React.useEffect(() => {
        const newCategorizations: SimCategorizationEntry[] = [];
        if (buildingId) {
            newCategorizations.push({ key: SimCategorizationType.BUILDING_ID, value: buildingId });
        }
        if (building) {
            newCategorizations.push({ key: SimCategorizationType.BUILDING, value: building });
        }
        if (country) {
            newCategorizations.push({ key: SimCategorizationType.COUNTRY, value: country });
        }
        if (city) {
            newCategorizations.push({ key: SimCategorizationType.CITY, value: city });
        }
        if (category) {
            newCategorizations.push({ key: SimCategorizationType.CATEGORY, value: category });
        }
        if (type) {
            newCategorizations.push({ key: SimCategorizationType.TYPE, value: type });
        }
        if (item) {
            newCategorizations.push({ key: SimCategorizationType.ITEM, value: item });
        }
        setCategorizations(newCategorizations);
    }, [buildingId, building, country, city, category, type, item]);

    return (
        <Column>
            <Input value={buildingId} onChange={setBuildingId} label='Building ID' />
            <Input value={building} onChange={setBuilding} label='Building' />
            <Input value={country} onChange={setCountry} label='Country' />
            <Input value={city} onChange={setCity} label='City' />
            <Input value={category} onChange={setCategory} label='Category' />
            <Input value={type} onChange={setType} label='Type' />
            <Input value={item} onChange={setItem} label='Item' />
        </Column>
    );
};

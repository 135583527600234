/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { AssignmentMap } from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration/types';
import { SimCategorizationEntry } from '@amzn/id4-mothership/com/amazon/id4notificationservice/model/types/sim';
import Column from '@amzn/meridian/column';
import { ColumnProps } from '@amzn/meridian/column/column';
import React from 'react';

import ControlledExpander from '../utility-views/ControlledExpander';
import { SimCategorizationInput } from './SimCategorizationInput';

export type AssignmentMapInputProps = {
    width?: ColumnProps['width']
    assignmentMap?: AssignmentMap  // Make this prop optional
    setAssignmentMap: (arg0: AssignmentMap) => void
    options: string[]
}

type SeverityLevel = 'MINOR' | 'MODERATE' | 'MAJOR' | 'CRITICAL' | 'SEVERE';

const AssignmentMapInput: React.FC<AssignmentMapInputProps> = ({width, assignmentMap = {}, setAssignmentMap}) => {
    const severityLevels: SeverityLevel[] = ['MINOR', 'MODERATE', 'MAJOR', 'CRITICAL', 'SEVERE'];

    const handleSeverityChange = (severity: SeverityLevel, value: SimCategorizationEntry[]) => {
        setAssignmentMap({
            ...assignmentMap,
            [severity]: value
        });
    };

    return (
        <Column width={width || '100%'}>
            {severityLevels.map((severity) => (
                <ControlledExpander key={severity} title={severity}>
                    <SimCategorizationInput
                        categorizations={assignmentMap[severity] || []}
                        setCategorizations={(value) => handleSeverityChange(severity, value)}
                    />
                </ControlledExpander>
            ))}
        </Column>
    );
};

export default AssignmentMapInput;

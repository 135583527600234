/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {SimConfiguration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/configuration';
import {
    SimAccessLevel,
    SimAutoUpgradePolicy,
    SimIdentity,
    SimIdentityNamespace,
    SimTag
} from '@amzn/id4-mothership/com/amazon/id4notificationservice/model/types/sim';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Textarea from '@amzn/meridian/textarea';
import Toggle from '@amzn/meridian/toggle';
import React from 'react';

import {areListsEqual} from '../../utility/custom-equality';
import AssignmentMapInput from '../Inputs/AssignmentMapInput';
import ClosureStrategyInput from '../Inputs/ClosureStrategyInput';
import DedupeStrategyInput from '../Inputs/DedupeStrategyInput';
import EscalationMapInput from '../Inputs/EscalationMapInput';
import ExpirationStrategyInput from '../Inputs/ExpirationStrategyInput';
import NotificationGroupingInput from '../Inputs/NotificationGroupingInput';
import {SimCategorizationInput} from '../Inputs/SimCategorizationInput';
import ControlledExpander from '../utility-views/ControlledExpander';
import DateTimePicker from '../utility-views/DateTimePicker';
import SearchSelect from '../utility-views/SearchSelect';

export type SimTemplateProps = {
    setTitleTemplate: React.Dispatch<React.SetStateAction<SimConfiguration['titleTemplate']>>
    setDescriptionTemplate: React.Dispatch<React.SetStateAction<SimConfiguration['descriptionTemplate']>>
    setAssignee: React.Dispatch<React.SetStateAction<SimConfiguration['assignee']>>
    setAssignedGroup: React.Dispatch<React.SetStateAction<SimConfiguration['assignedGroup']>>
    setRequester: React.Dispatch<React.SetStateAction<SimConfiguration['requester']>>
    setCategorization: React.Dispatch<React.SetStateAction<SimConfiguration['categorization']>>
    setEscalationMap: React.Dispatch<React.SetStateAction<SimConfiguration['escalationMap']>>
    setEstimatedStartTime: React.Dispatch<React.SetStateAction<SimConfiguration['estimatedStartTime']>>
    setEstimatedCompletionTime: React.Dispatch<React.SetStateAction<SimConfiguration['estimatedCompletionTime']>>
    setTags: React.Dispatch<React.SetStateAction<SimConfiguration['tags']>>
    setAccessLevel: React.Dispatch<React.SetStateAction<SimConfiguration['accessLevel']>>
    setAutoUpgrade: React.Dispatch<React.SetStateAction<SimConfiguration['autoUpgrade']>>
    setConcerning: React.Dispatch<React.SetStateAction<SimConfiguration['concerning']>>
    setRelationshipToConcerning: React.Dispatch<React.SetStateAction<SimConfiguration['relationshipToConcerning']>>
    setNotificationGrouping: React.Dispatch<React.SetStateAction<SimConfiguration['notificationGrouping']>>
    setDedupeStrategy: React.Dispatch<React.SetStateAction<SimConfiguration['dedupeStrategy']>>
    setExpirationStrategy: React.Dispatch<React.SetStateAction<SimConfiguration['expirationStrategy']>>
    setClosureStrategy: React.Dispatch<React.SetStateAction<SimConfiguration['closureStrategy']>>
    setProblemResolver: React.Dispatch<React.SetStateAction<SimConfiguration['problemResolver']>>;
    setAssignmentMap: React.Dispatch<React.SetStateAction<SimConfiguration['assignmentMap']>>;
    simConfiguration: SimConfiguration

}
const SimTemplate = ({
    simConfiguration, setTitleTemplate, setDescriptionTemplate, setAssignee, setAssignedGroup, setRequester, setCategorization,
    setEscalationMap, setEstimatedStartTime, setEstimatedCompletionTime, setTags, setAccessLevel, setAutoUpgrade, setConcerning,
    setRelationshipToConcerning, setNotificationGrouping, setDedupeStrategy, setExpirationStrategy, setClosureStrategy, setProblemResolver,
    setAssignmentMap,
}: SimTemplateProps) => {

    const {
        titleTemplate, descriptionTemplate, assignee, assignedGroup, requester, categorization, accessLevel,
        autoUpgrade, tags, concerning, relationshipToConcerning, estimatedStartTime, estimatedCompletionTime,
        escalationMap, notificationGrouping, dedupeStrategy, expirationStrategy, closureStrategy, problemResolver,
        assignmentMap
    } = simConfiguration || {};

    // Options available in SIM which users can map ID4 problems to - note that these are ticket severities, and we do not support sev 1
    const SIM_ESCALATION_MAP_SEVERITY_OPTIONS = ['SEV_2', 'SEV_3', 'SEV_4', 'SEV_5'];

    /**
     * Effect which will set some SIM template properties that cannot be undefined but also don't need to be filled out.
     */
    React.useEffect(() => {
        if (escalationMap === undefined) {
            setEscalationMap({});
        }
        if (categorization === undefined) {
            setCategorization([]);
        }
    }, []);

    return (
        <Column width='100%'>
            <Toggle checked={problemResolver} onChange={setProblemResolver}>Enable problem resolution?</Toggle>
            <Textarea value={titleTemplate} onChange={setTitleTemplate} placeholder='Enter title' label='Title' rows={1}/>
            <Textarea value={descriptionTemplate} onChange={setDescriptionTemplate} placeholder='Enter description' label='Description' />
            <SimIdentityInput label='Assignee' identity={assignee} setIdentity={setAssignee} />
            <SimIdentityInput label='Assigned group' identity={assignedGroup} setIdentity={setAssignedGroup} />
            <SimIdentityInput label='Requester' identity={requester} setIdentity={setRequester} />
            <ControlledExpander title='Categorization'>
                <SimCategorizationInput categorizations={categorization} setCategorizations={setCategorization} />
            </ControlledExpander>
            <ControlledExpander title='Escalation map'>
                <EscalationMapInput options={SIM_ESCALATION_MAP_SEVERITY_OPTIONS} escalationMap={escalationMap} setEscalationMap={setEscalationMap} />
            </ControlledExpander>
            <ControlledExpander title='Assignment map'>
                <AssignmentMapInput
                    options={SIM_ESCALATION_MAP_SEVERITY_OPTIONS}
                    assignmentMap={assignmentMap}
                    setAssignmentMap={setAssignmentMap}
                />
            </ControlledExpander>
            <DateTimePicker label='Estimated start time' disablePastDates={true} inputValueInEpochMs={estimatedStartTime} setInputValueInEpochMs={setEstimatedStartTime} />
            <DateTimePicker label='Estimated completion time' disablePastDates={true} inputValueInEpochMs={estimatedCompletionTime} setInputValueInEpochMs={setEstimatedCompletionTime} />
            <SimTagInput tags={tags} setTags={setTags} />
            <SimAccessLevelInput accessLevel={accessLevel} setAccessLevel={setAccessLevel} />
            <SimAutoUpgradePolicyInput autoUpgradePolicy={autoUpgrade} setAutoUpgradePolicy={setAutoUpgrade} />
            <Input value={concerning} onChange={setConcerning} label='Concerning' />
            <Input value={relationshipToConcerning} onChange={setRelationshipToConcerning} label='Relationship to concerning' />
            <NotificationGroupingInput notificationGroupingValue={notificationGrouping} setNotificationGroupingValue={setNotificationGrouping} />
            <DedupeStrategyInput dedupeStrategyValue={dedupeStrategy} setDedupeStrategyValue={setDedupeStrategy} />
            <ExpirationStrategyInput expirationStrategyValue={expirationStrategy} setExpirationStrategyValue={setExpirationStrategy} />
            <ClosureStrategyInput closureStrategyValue={closureStrategy} setClosureStrategyValue={setClosureStrategy} />
        </Column>
    );
};

const SimAutoUpgradePolicyInput = ({autoUpgradePolicy, setAutoUpgradePolicy}: {autoUpgradePolicy: SimAutoUpgradePolicy, setAutoUpgradePolicy: (arg0: SimAutoUpgradePolicy) => void}) => {
    return (
        <SearchSelect
            label='Auto-upgrade policy'
            placeholder='Select auto-upgrade policy'
            options={Object.entries(SimAutoUpgradePolicy).map(([label, value]) => ({ label, value }))}
            isLoading={false}
            loadingMessage='Loading auto-upgrade policies'
           
            selectedValue={autoUpgradePolicy}
            setSelectedValue={setAutoUpgradePolicy}
        />
    );
};

const SimIdentityInput = ({label, identity, setIdentity}: {label: string, identity: SimIdentity, setIdentity: React.Dispatch<React.SetStateAction<SimIdentity>>}) => {

    const [namespace, setNamespace] = React.useState<SimIdentityNamespace>(identity?.namespace);
    const [value, setValue] = React.useState<string>(identity?.value);

    /**
     * Effect to set the initial state when the passed in identity changes.
     */
    React.useEffect(() => {
        setNamespace(identity?.namespace);
        setValue(identity?.value);
    }, [identity?.namespace, identity?.value]);

    /**
     * Effect to set the identity when the namespace or value state updates locally.
     */
    React.useEffect(() => {
        // If namespace or value is defined, then set the object. Otherwise, set undefined because the mothership
        // model requires both fields to be filled out
        setIdentity(namespace || value ? {namespace, value} : undefined);
    }, [namespace, value]);

    return (
        <Column data-testid={`sim-identity-input-${label}`}>
            <Text type='h100'>{label}</Text>
            <Row width='100%'>
                <SearchSelect
                    label='Namespace'
                    placeholder='Select namespace'
                    options={Object.entries(SimIdentityNamespace).map(([label, value]) => ({ label, value }))}
                    isLoading={false}
                    loadingMessage='Loading namespaces...'
                    selectedValue={namespace}
                    setSelectedValue={(value: string) => setNamespace(value as SimIdentityNamespace)}
                    width='50%'
                />
                <Input
                    label='Value'
                    value={value}
                    onChange={(value: string) => setValue(value !== '' ? value : undefined)}
                />
            </Row>
        </Column>
    );
};

const SimAccessLevelInput = ({accessLevel, setAccessLevel}: {accessLevel: SimAccessLevel, setAccessLevel: (arg0: SimAccessLevel) => void}) => {
    return (
        <Column data-testid='sim-access-level-input'>
            <SearchSelect
                label='Access level'
                placeholder='Select access level'
                options={Object.entries(SimAccessLevel).map(([label, value]) => ({ label, value }))}
                isLoading={false}
                loadingMessage='Loading access levels...'
                selectedValue={accessLevel}
                setSelectedValue={(value: string) => setAccessLevel(value as SimAccessLevel)}
            />
        </Column>
    );
};

const SimTagInput = ({tags, setTags}: {tags: SimTag[], setTags: (arg0: SimTag[]) => void}) => {

    const tagsOrEmptyList = tags ?? [];
    const initialValue = tagsOrEmptyList.map(tag => tag.tagId).join('\n');
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        if (value) {
            const valueAsList = value.split('\n').filter(val => val).map(val => ({tagId: val}));
            if (!areListsEqual(valueAsList, tagsOrEmptyList)) {
                setTags(valueAsList);
            }
        } else {
            if (tagsOrEmptyList.length > 0) {
                setTags([]);
            }
        }
    }, [value]);

    return (
        <Column data-testid='sim-tag-input'>
            <Textarea
                label='Tags'
                placeholder={'tag1\ntag2'}
                value={value}
                onChange={setValue}
                constraintText={'Separate tags with a new line'}
            />
        </Column>
    );
};

export default SimTemplate;
